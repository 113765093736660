import { Currency, Token } from '@ignitefinance/igniteswap-v2-sdk'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Modal from '../Modal'
import CurrencyList from 'components/SearchModal/CurrencyList'
import AutoSizer from 'react-virtualized-auto-sizer'
import styled from 'styled-components'
import Column from 'components/Column'
import { useFaucetTokens } from 'hooks/Tokens'

const ContentWrapper = styled(Column)`
  width: 100%;
  flex: 1 1;
  position: relative;
`

interface CurrencySearchModalProps {
  isOpen: boolean
  onDismiss: () => void
  selectedCurrency?: Currency | null
  onCurrencySelect: (currency: Token) => void
  otherSelectedCurrency?: Currency | null
  showCommonBases?: boolean
}

export default function FaucetTokenSelectModal({
  isOpen,
  onDismiss,
  onCurrencySelect,
  selectedCurrency
}: CurrencySearchModalProps) {
  const handleCurrencySelect = useCallback(
    (currency: Token) => {
      onCurrencySelect(currency)
      onDismiss()
    },
    [onDismiss, onCurrencySelect]
  )
  // change min height if not searching
  const minHeight = 80

  const allTokens = useFaucetTokens()
  const faucetTokens: Token[] = useMemo(() => {
    return Object.values(allTokens)
  }, [allTokens])

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxHeight={80} minHeight={minHeight}>
      <ContentWrapper>
        <div style={{ flex: '1' }}>
          <AutoSizer disableWidth>
            {({ height }) => (
              <CurrencyList
                height={height}
                onCurrencySelect={handleCurrencySelect as (currency: Currency) => void}
                selectedCurrency={selectedCurrency}
                showImportView={() => null}
                setImportToken={() => null}
                showETH={false}
                currencies={faucetTokens}
                breakIndex={undefined} // don't show import statement
              />
            )}
          </AutoSizer>
        </div>
      </ContentWrapper>
    </Modal>
  )
}
