import { MaxUint256 } from '@ethersproject/constants'
import { TransactionResponse } from '@ethersproject/providers'
import { Trade, TokenAmount, CurrencyAmount, ETHER, Currency, Token, BigintIsh } from '@ignitefinance/igniteswap-v2-sdk'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { FAUCET_AMOUNT } from '../constants'
import { useTokenAllowance } from '../data/Allowances'
import { Field } from '../state/swap/actions'
import { useTransactionAdder, useHasPendingApproval } from '../state/transactions/hooks'
import { calculateGasMargin } from '../utils'
import { useFaucetContract } from './useContract'
import { useActiveWeb3React } from './index'
import { useWeb3React } from '@web3-react/core'
import { useAddPopup } from 'state/application/hooks'
import { useTokenBalance } from 'state/wallet/hooks'
import { ethers } from 'ethers'

export enum FaucetState {
  UNKNOWN,
  NOT_APPROVED,
  PENDING,
  APPROVED
}

// returns a variable indicating the state of the approval and a function which approves if necessary or early returns
export function useFaucetCallback(token: Token | undefined): [FaucetState, () => Promise<void>] {
  // check the current approval status
  const { account } = useWeb3React()

  const faucetContract = useFaucetContract()
  const addTransaction = useTransactionAdder()
  const currentBalance = useTokenBalance(account ?? undefined, token)
  const pendingApproval = useHasPendingApproval(token?.address, account ?? undefined)

  const [balanceLater, setBalanceLater] = useState<TokenAmount>()
  useEffect(() => {
    if (!token || !currentBalance) return
    setBalanceLater(currentBalance?.add(new TokenAmount(token, FAUCET_AMOUNT.toString())))
  }, [token])

  // check the current approval status
  const faucetState: FaucetState = useMemo(() => {
    if (!token || !account) return FaucetState.UNKNOWN
    // we might not have enough data to know whether or not we need to approve
    if (!balanceLater) return FaucetState.NOT_APPROVED
    if (!currentBalance) return FaucetState.UNKNOWN
    // amountToApprove will be defined if currentAllowance is
    return currentBalance.lessThan(balanceLater)
      ? pendingApproval
        ? FaucetState.PENDING
        : FaucetState.NOT_APPROVED
      : FaucetState.APPROVED
  }, [currentBalance, pendingApproval, account, token, balanceLater])
  // show popup on confirm
  const addPopup = useAddPopup()

  const getFaucet = useCallback(async (): Promise<void> => {
    if (faucetState !== FaucetState.NOT_APPROVED) {
      console.error('approve was called unnecessarily')
      return
    }
    if (!faucetContract) {
      console.error('tokenContract is null')
      return
    }

    if (!token) {
      console.error('missing amount to approve')
      return
    }

    if (!account) {
      console.error('no spender')
      return
    }

    faucetContract.estimateGas
      .claimTokens(token.address, FAUCET_AMOUNT)
      .then(estimatedGas => {
        return faucetContract
          .claimTokens(token.address, FAUCET_AMOUNT, {
            gasLimit: calculateGasMargin(estimatedGas)
          })
          .then((response: TransactionResponse) => {
            addTransaction(response, {
              summary: 'Faucet Receive ' + ethers.utils.formatEther(FAUCET_AMOUNT) + ' ' + token.name,
              approval: { tokenAddress: token.address, spender: account }
            })
          })
          .catch((error: Error) => {
            console.debug('Failed to approve token', error)
            throw error
          })
      })
      .catch((error: any) => {
        if (error?.data?.message?.includes('Claiming not allowed yet')) {
          addPopup({
            success: false,
            summary: 'You can only claim once an hour.'
          })
        }
      })
  }, [faucetState, faucetContract, addTransaction, account, token, addPopup])

  return [faucetState, getFaucet]
}
