import React, { RefObject, useCallback, useContext, useMemo, useRef, useState } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { Pair, JSBI, Currency, Token, TokenAmount } from '@ignitefinance/igniteswap-v2-sdk'
import { Download, Loader, Minus, Plus, PlusCircle, Search } from 'react-feather'

import FullPositionCard from '../../components/PositionCard'
import { useTokenBalance, useTokenBalancesWithLoadingIndicator } from '../../state/wallet/hooks'
import { ExternalLink, TYPE, HideSmall } from '../../theme'
import { Button, Text } from 'rebass'
import Row, { AutoRow, RowBetween, RowFixed } from '../../components/Row'
import { ButtonConfirmed, ButtonEmpty, ButtonLight } from '../../components/Button'
import { AutoColumn } from '../../components/Column'
import { useWalletModalToggle } from '../../state/application/hooks'

import { useActiveWeb3React } from '../../hooks'
import { usePairs } from '../../data/Reserves'
import { toV2LiquidityToken, useTrackedTokenPairs } from '../../state/user/hooks'
import { useStakingInfo } from '../../state/stake/hooks'
import { BIG_INT_ZERO, FAQ_DATA } from '../../constants'
import { FaucetState, useFaucetCallback } from '../../hooks/useFaucetCallback'
import FaucetTokenSelectModal from 'components/FaucetTokenSelectModal'
import CurrencyLogo from 'components/CurrencyLogo'
import { useTranslation } from 'react-i18next'
import { ReactComponent as DropDown } from '../../assets/images/dropdown.svg'
import { darken } from 'polished'

const PageWrapper = styled(AutoColumn)`
  max-width: 640px;
  width: 100%;
`

const TitleRow = styled(AutoRow)`
  width: 100%;
  gap: 12px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-wrap: wrap;
    flex-direction: row;
  `};
`

export const PoolListWrapper = styled.div`
  position: relative;
  width: 100%;
  background: ${({ theme }) => theme.bg7};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 10px;
  margin-top: 100px;
  padding: 1rem;
`

const ClaimButton = styled(ButtonConfirmed)`
  width: auto;
  flex: auto;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
  `};
`

const CurrencySelect = styled.button<{ selected: boolean }>`
  align-items: center;
  height: 2.2rem;
  font-size: 20px;
  font-weight: 500;
  background-color: ${({ selected, theme }) => (selected ? theme.bg6 : theme.bg6)};
  color: ${({ selected, theme }) => (selected ? theme.text1 : theme.text1)};
  border-radius: 6px;
  box-shadow: ${({ selected }) => (selected ? 'none' : '0px 6px 10px rgba(0, 0, 0, 0.075)')};
  outline: none;
  cursor: pointer;
  user-select: none;
  border-color: ${({ selected, theme }) => (selected ? theme.bg6 : theme.bg3)};
  padding: 0 0.5rem;

  :focus,
  :hover {
    background-color: ${({ selected, theme }) => (selected ? theme.bg7 : darken(0.05, theme.bg3))};
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
  `};
`
const StyledTokenName = styled.span<{ active?: boolean }>`
  ${({ active }) => (active ? '  margin: 0 0.25rem 0 0.75rem;' : '  margin: 0 0.25rem 0 0.25rem;')}
  font-size:  ${({ active }) => (active ? '14px' : '14px')};

`

const Aligner = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const StyledDropDown = styled(DropDown)<{ selected: boolean }>`
  margin: 0 0.25rem 0 0.5rem;
  height: 35%;

  path {
    stroke: ${({ selected, theme }) => (selected ? theme.text1 : theme.text1)};
    stroke-width: 1.5px;
  }
`
const FaqContent = styled.div`
  flex: auto;
`
const FaqRow = styled(Row)`
  align-items: start;
  margin-bottom: 50px;
  cursor: pointer;
`
export const FaqNumber = styled(TYPE.darkGray)`
  flex: 0 0 50px;
  font-size: 20px;
`
export default function Faucet() {
  const { account } = useActiveWeb3React()

  // fetch the user's balances of all tracked V2 LP tokens
  const trackedTokenPairs = useTrackedTokenPairs()
  const tokenPairsWithLiquidityTokens = useMemo(
    () => trackedTokenPairs.map(tokens => ({ liquidityToken: toV2LiquidityToken(tokens), tokens })),
    [trackedTokenPairs]
  )
  const liquidityTokens = useMemo(() => tokenPairsWithLiquidityTokens.map(tpwlt => tpwlt.liquidityToken), [
    tokenPairsWithLiquidityTokens
  ])
  const [v2PairsBalances, fetchingV2PairBalances] = useTokenBalancesWithLoadingIndicator(
    account ?? undefined,
    liquidityTokens
  )

  // fetch the reserves for all V2 pools in which the user has a balance
  const liquidityTokensWithBalances = useMemo(
    () =>
      tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) =>
        v2PairsBalances[liquidityToken.address]?.greaterThan('0')
      ),
    [tokenPairsWithLiquidityTokens, v2PairsBalances]
  )

  // show liquidity even if its deposited in rewards contract
  const stakingInfo = useStakingInfo()
  const stakingInfosWithBalance = stakingInfo?.filter(pool => JSBI.greaterThan(pool.stakedAmount.raw, BIG_INT_ZERO))

  // refs for fixed size lists
  const poolListRef = useRef<HTMLDivElement>()

  const [faucetCurrency, setFaucetCurrency] = useState<Token>()
  const [faucet, faucetCallback] = useFaucetCallback(faucetCurrency)

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [modalOpen, setModalOpen] = useState(false)
  const handleDismissSearch = useCallback(() => {
    setModalOpen(false)
  }, [setModalOpen])
  const { t } = useTranslation()

  // toggle wallet when disconnected
  const toggleWalletModal = useWalletModalToggle()
  const [selectedFaq, setSelectedFaq] = useState(0)

  return (
    <>
      <PageWrapper>
        <Row justify="center" padding={'10px'} style={{ marginTop: '50px' }}>
          <TYPE.pageHeader marginRight={'10px'}>IGNITE</TYPE.pageHeader>{' '}
          <TYPE.pageHeader color={'primary1'}>FAUCET</TYPE.pageHeader>
        </Row>
        <TYPE.small fontSize={12} textAlign={'center'}>
          Obtain IGN Testnet tokens every 24 hours for seamless and confident development.
        </TYPE.small>
        <PoolListWrapper>
          <AutoColumn gap="lg" justify="center">
            <AutoColumn gap="lg" style={{ width: '100%' }} ref={poolListRef as RefObject<HTMLDivElement>}>
              <TitleRow>
                <CurrencySelect
                  selected={!!faucetCurrency}
                  className="open-currency-select-button"
                  onClick={() => {
                    setModalOpen(true)
                  }}
                >
                  <Aligner>
                    {faucetCurrency ? <CurrencyLogo currency={faucetCurrency} size={'24px'} /> : null}
                    {
                      <StyledTokenName
                        className="token-symbol-container"
                        active={Boolean(faucetCurrency && faucetCurrency.symbol)}
                      >
                        {faucetCurrency && faucetCurrency.symbol ? `1000 ${faucetCurrency.symbol}` : t('selectToken')}
                      </StyledTokenName>
                    }
                    {<StyledDropDown selected={!!faucetCurrency} />}
                  </Aligner>
                </CurrencySelect>
                {!account ? (
                  <ClaimButton onClick={toggleWalletModal} padding="6px 8px" style={{ borderRadius: '8px' }}>
                    Connect Wallet
                  </ClaimButton>
                ) : (
                  <ClaimButton
                    padding="6px 8px"
                    style={{ borderRadius: '8px' }}
                    disabled={!faucetCurrency || faucet !== FaucetState.NOT_APPROVED}
                    altDisabledStyle={faucet === FaucetState.PENDING} // show solid button while waiting
                    confirmed={faucet === FaucetState.APPROVED}
                    onClick={faucetCallback}
                  >
                    {faucet === FaucetState.PENDING ? (
                      <AutoRow gap="6px" justify="center">
                        Sending <Loader stroke="white" />
                      </AutoRow>
                    ) : faucet === FaucetState.APPROVED ? (
                      'Claimed'
                    ) : (
                      `Claim`
                    )}
                  </ClaimButton>
                )}
              </TitleRow>
            </AutoColumn>
          </AutoColumn>
        </PoolListWrapper>
        <FaucetTokenSelectModal
          isOpen={modalOpen}
          onDismiss={handleDismissSearch}
          onCurrencySelect={setFaucetCurrency}
          selectedCurrency={faucetCurrency}
        />
        <Row justify="center" padding={'10px'} style={{ marginTop: '100px', marginBottom: '50px' }}>
          <TYPE.white fontSize={32}>FAQ</TYPE.white>{' '}
        </Row>
        {FAQ_DATA.map((faq, idx) => (
          <FaqRow key={idx} onClick={() => setSelectedFaq(selectedFaq == idx + 1 ? 0 : idx + 1)}>
            <FaqNumber>0{idx + 1}</FaqNumber>
            <FaqContent>
              <TYPE.white padding={'2px'} fontSize={18}>
                {faq.q}
              </TYPE.white>
              {selectedFaq == idx + 1 && (
                <TYPE.darkGray style={{ paddingTop: '25px', paddingBottom: '25px' }}>
                  <div dangerouslySetInnerHTML={{ __html: faq.a }} />
                </TYPE.darkGray>
              )}
            </FaqContent>
            {selectedFaq == idx + 1 ? <Minus size={24} /> : <Plus size={24} />}
          </FaqRow>
        ))}
      </PageWrapper>
    </>
  )
}
