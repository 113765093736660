import React, { RefObject, useCallback, useContext, useMemo, useRef, useState } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { Pair, JSBI } from '@ignitefinance/igniteswap-v2-sdk'
import { Link } from 'react-router-dom'
import { Download, PlusCircle, Search } from 'react-feather'
import { SwapPoolTabs } from '../../components/NavigationTabs'

import FullPositionCard from '../../components/PositionCard'
import { useTokenBalancesWithLoadingIndicator } from '../../state/wallet/hooks'
import { ExternalLink, TYPE, HideSmall } from '../../theme'
import { Text } from 'rebass'
import Card from '../../components/Card'
import { AutoRow, RowBetween, RowFixed } from '../../components/Row'
import { ButtonPrimary, ButtonSecondary, ButtonGray } from '../../components/Button'
import { AutoColumn } from '../../components/Column'

import { useActiveWeb3React } from '../../hooks'
import { usePairs } from '../../data/Reserves'
import { toV2LiquidityToken, useTrackedTokenPairs } from '../../state/user/hooks'
import { Dots } from '../../components/swap/styleds'
import { CardSection, DataCard, CardNoise, CardBGImage, TextBox } from '../../components/earn/styled'
import { useStakingInfo } from '../../state/stake/hooks'
import { BIG_INT_ZERO } from '../../constants'
import { isAddress } from 'utils'

const PageWrapper = styled(AutoColumn)`
  max-width: 1280px;
  width: 100%;
`

const VoteCard = styled(DataCard)`
  background: radial-gradient(76.02% 75.41% at 1.84% 0%, #27ae60 0%, #000000 100%);
  overflow: hidden;
`

const TitleRow = styled(AutoRow)`
  width: 100%;
  gap: 12px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-wrap: wrap;
    flex-direction: row;
  `};
`

const ButtonRow = styled(RowFixed)`
  gap: 8px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  `};
`

const ResponsiveButtonPrimary = styled(ButtonPrimary)`
  width: fit-content;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 48%;
  `};
`

const ResponsiveButtonGray = styled(ButtonGray)`
  width: fit-content;
  color: ${({ theme }) => theme.white};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 48%;
  `};
`

const EmptyProposals = styled.div`
  border: 1px solid ${({ theme }) => theme.text4};
  padding: 16px 12px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const PoolListWrapper = styled.div`
  position: relative;
  width: 100%;
  background: ${({ theme }) => theme.bg7};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 10px;
  margin-top: 50px;
  padding: 1rem;
`

const SearchRow = styled(AutoRow)`
  width: auto;
  flex: auto;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
  `};
`

const SearchInput = styled.input`
  width: 100%;
  padding: 8px;
  padding-left: 40px;
  align-items: center;
  white-space: nowrap;
  background: none;
  border: none;
  outline: none;
  border-radius: 8px;
  color: ${({ theme }) => theme.text1};
  border-style: solid;
  border: 1px solid ${({ theme }) => theme.bg3};
  -webkit-appearance: none;

  font-size: 16px;

  ::placeholder {
    color: ${({ theme }) => theme.text3};
  }
  transition: border 100ms;
  :focus {
    border: 1px solid ${({ theme }) => theme.primary1};
    outline: none;
  }
`

const TH = styled.th`
  text-align: left;
  padding: 25px;
  color: ${({ theme }) => theme.text3};
  font-size: 14px;
  font-weight: 500;
`

export default function Pool() {
  const theme = useContext(ThemeContext)
  const { account } = useActiveWeb3React()

  // fetch the user's balances of all tracked V2 LP tokens
  const trackedTokenPairs = useTrackedTokenPairs()
  const tokenPairsWithLiquidityTokens = useMemo(
    () => trackedTokenPairs.map(tokens => ({ liquidityToken: toV2LiquidityToken(tokens), tokens })),
    [trackedTokenPairs]
  )
  const liquidityTokens = useMemo(() => tokenPairsWithLiquidityTokens.map(tpwlt => tpwlt.liquidityToken), [
    tokenPairsWithLiquidityTokens
  ])
  const [v2PairsBalances, fetchingV2PairBalances] = useTokenBalancesWithLoadingIndicator(
    account ?? undefined,
    liquidityTokens
  )

  // fetch the reserves for all V2 pools in which the user has a balance
  const liquidityTokensWithBalances = useMemo(
    () =>
      tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) =>
        v2PairsBalances[liquidityToken.address]?.greaterThan('0')
      ),
    [tokenPairsWithLiquidityTokens, v2PairsBalances]
  )

  const v2Pairs = usePairs(liquidityTokensWithBalances.map(({ tokens }) => tokens))
  const v2IsLoading =
    fetchingV2PairBalances || v2Pairs?.length < liquidityTokensWithBalances.length || v2Pairs?.some(V2Pair => !V2Pair)

  const allV2PairsWithLiquidity = v2Pairs.map(([, pair]) => pair).filter((v2Pair): v2Pair is Pair => Boolean(v2Pair))

  // show liquidity even if its deposited in rewards contract
  const stakingInfo = useStakingInfo()
  const stakingInfosWithBalance = stakingInfo?.filter(pool => JSBI.greaterThan(pool.stakedAmount.raw, BIG_INT_ZERO))
  const stakingPairs = usePairs(stakingInfosWithBalance?.map(stakingInfo => stakingInfo.tokens))

  // remove any pairs that also are included in pairs with stake in mining pool
  const v2PairsWithoutStakedAmount = allV2PairsWithLiquidity.filter(v2Pair => {
    return (
      stakingPairs
        ?.map(stakingPair => stakingPair[1])
        .filter(stakingPair => stakingPair?.liquidityToken.address === v2Pair.liquidityToken.address).length === 0
    )
  })

  // refs for fixed size lists
  const poolListRef = useRef<HTMLDivElement>()

  const [searchQuery, setSearchQuery] = useState<string>('')
  const handleInput = useCallback(event => {
    const input = event.target.value
    const checksummedInput = isAddress(input)
    setSearchQuery(checksummedInput || input)
    poolListRef.current?.scrollTo({ top: 0 })
  }, [])

  return (
    <>
      <PageWrapper>
        <SwapPoolTabs active={'pool'} />
        <VoteCard>
          <CardBGImage />
          <CardNoise />
          <CardBGImage />
          <CardNoise />
        </VoteCard>
        <TYPE.pageHeader paddingBottom={'10px'}>Liquidity</TYPE.pageHeader>
        <TYPE.darkGray fontSize={12}>Create and manage your all liquidity positions.</TYPE.darkGray>
        <PoolListWrapper>
          <AutoColumn gap="lg" justify="center">
            <AutoColumn gap="lg" style={{ width: '100%' }} ref={poolListRef as RefObject<HTMLDivElement>}>
              <TitleRow padding={'0'}>
                <SearchRow>
                  <Search style={{ position: 'absolute', left: '30px' }} size={16} />
                  <SearchInput
                    type="text"
                    id="token-search-input"
                    placeholder={'Search for tokens or address...'}
                    autoComplete="off"
                    value={searchQuery}
                    onChange={handleInput}
                  />
                </SearchRow>
                <ButtonRow>
                  <ResponsiveButtonGray as={Link} padding="6px 8px" to="/find" style={{ borderRadius: '6px' }}>
                    <Download size={'16'} style={{ margin: '2px' }} />
                    <Text fontWeight={500} fontSize={14} marginLeft={'4px'}>
                      Import token
                    </Text>
                  </ResponsiveButtonGray>
                  <ResponsiveButtonPrimary
                    id="join-pool-button"
                    as={Link}
                    padding="6px 8px"
                    style={{ borderRadius: '6px' }}
                    to="/add/ETH"
                  >
                    <PlusCircle size="16" style={{ margin: '2px' }} />
                    <Text fontWeight={500} fontSize={14} marginLeft={'4px'}>
                      New position
                    </Text>
                  </ResponsiveButtonPrimary>
                </ButtonRow>
              </TitleRow>

              {!account ? (
                <Card padding="40px">
                  <TYPE.body color={theme.text3} textAlign="center">
                    Connect to a wallet to view your liquidity.
                  </TYPE.body>
                </Card>
              ) : v2IsLoading ? (
                <EmptyProposals>
                  <TYPE.body color={theme.text3} textAlign="center">
                    <Dots>Loading</Dots>
                  </TYPE.body>
                </EmptyProposals>
              ) : allV2PairsWithLiquidity?.length > 0 || stakingPairs?.length > 0 ? (
                <>
                  {/* <ButtonSecondary>
                    <RowBetween>
                      <ExternalLink href={'https://Igniteswap.info/account/' + account}>
                        Account analytics and accrued fees
                      </ExternalLink>
                      <span> ↗</span>
                    </RowBetween>
                  </ButtonSecondary> */}
                  <table>
                    <tr>
                      <TH>Name</TH>
                      <TH>Amount</TH>
                      <TH>Composition</TH>
                      <TH>Fees APR</TH>
                      <TH>Pool share</TH>
                      <TH></TH>
                    </tr>
                    {v2PairsWithoutStakedAmount.map(v2Pair => (
                      <FullPositionCard key={v2Pair.liquidityToken.address} pair={v2Pair} />
                    ))}
                    {stakingPairs.map(
                      (stakingPair, i) =>
                        stakingPair[1] && ( // skip pairs that arent loaded
                          <FullPositionCard
                            key={stakingInfosWithBalance[i].stakingRewardAddress}
                            pair={stakingPair[1]}
                            stakedBalance={stakingInfosWithBalance[i].stakedAmount}
                          />
                        )
                    )}
                  </table>
                </>
              ) : (
                <EmptyProposals>
                  <TYPE.body color={theme.text3} textAlign="center">
                    No liquidity found.
                  </TYPE.body>
                </EmptyProposals>
              )}
            </AutoColumn>
          </AutoColumn>
        </PoolListWrapper>
      </PageWrapper>
    </>
  )
}
